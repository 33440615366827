<template>

  <div>
    <div v-for="(item, i) in items" class="row mb-1">
      <div class="col-auto pe-0">
        <span class="badge rounded-pill badge-soft-primary font-size-12" :class="`badge-soft-${ item.titleBg ?? 'primary' }`">{{ item.title }}</span>
      </div>
      <div class="col">
        <span :class="item.contentTextClass ?? `text-muted fs-6`"> {{ !$_utils.isEmpty(item.content) ? item.content : '-'  }} </span>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  props: {
    items: Array, //[{ title: '플랜명', titleBg: 'primary', content: '제로엔' , contentTextClass: 'text-bold'}]
  }
}
</script>
