<template>
  <div class="d-flex">
    <div v-for="(item, i) in nullCheckedItems">
      <div class="pe-2 border-primary" :class="`${ i !== nullCheckedItems.length - 1 ? 'right-border-divider' : '' } ${ i !== 0 ? 'ps-2' : '' }`">
        <div :class="textClass ?? 'text-muted fs-6'" style="line-height: 1.1">{{ item }}</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    items: Array,
    textClass: String,
  },
  computed: {
    nullCheckedItems() {
      return this.items.filter(ele => !this.$_utils.isEmpty(ele));

    }
  }
}
</script>
