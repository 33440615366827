<template>
  <b-form-select class="form-select me-4 py-1"
                 v-model="localValue"
                 :options="pageSizeOption"
                 @change="handleChange"
  />
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'PerPageSelector',
  props: {
    value: {
      type: Number,
      required: true
    },
    option: {
      type: Array,
      required: true
    }
  },
  created() {
    const vm = this;
    vm.pageSizeOption = this.option.map(ele => ({
      value: ele,
      text: `${ele}${i18n.t('unit.case')}`
    }));
  },
  watch: {
    value(val) {
      this.localValue = val;
    },
    localValue(val) {
      this.$emit('input', val);
    }
  },
  data() {
    return {
      localValue: this.value,
      pageSizeOption : []
    }
  },
  methods: {
    handleChange() {
      this.$emit('change');
    }
  }
}
</script>
