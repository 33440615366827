<template>
  <div class="row d-flex align-items-center">
    <div v-if="!$_utils.isEmpty(iconClassName)" class="col-auto">
      <div class="avatar">
        <div class="avatar-title bg-light text-primary rounded-circle pb-1">
          <i :class="iconClassName" :style="{ fontSize: iconSize }"></i>
        </div>
      </div>
    </div>
    <div class="col">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemProfileLayout",
  props: {
    iconClassName: String,
    iconSize: {
      type: String ,
      default: '30px',
    },
  },
}
</script>